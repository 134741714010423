module services {
    export module costing {

        export class costParameterWarehouseCustomAttributeService implements interfaces.costing.ICostParameterWarehouseCustomAttributeService {

            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getWarehouseCustomAttributeList(): ng.resource.IResourceClass<interfaces.costing.ICostWarehouseCustomAttribute> {
                return this.$resource<interfaces.costing.ICostWarehouseCustomAttribute>(this.ENV.DSP_URL + "CostParameterWarehouseCustomAttribute/GetWarehouseCustomAttributeList", {
                    showAllActive: '@showAllActive',
                    entityId: '@entityId',
                    warehouseId: '@warehouseId',
                    customAttributeId: '@customAttributeId',
                    customAttributeValueId: '@customAttributeValueId'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            saveWarehouseCustomAttribute(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterWarehouseCustomAttribute/SaveWarehouseCustomAttribute", {
                });
            }

            removeWarehouseCustomAttribute(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CostParameterWarehouseCustomAttribute/RemoveWarehouseCustomAttribute", {
                    cwcId: '@cwcId',
                });
            }


        }

    }

    angular.module("app").service("costParameterWarehouseCustomAttributeService", services.costing.costParameterWarehouseCustomAttributeService);
}